

import { useRoute, useRouter } from 'vue-router';


export default{
  setup(){
    const router = useRouter();
    const route = useRoute();
    const id = route.params.id; // read parameter id (it is reactive)

  }
}

